import React from "react";
import Layout from "../components/layout";
import Opensource from "../components/views/opensource";

const OpensourcePage = () => {
  return (
    <Layout>
      <Opensource />
    </Layout>
  );
};

export default OpensourcePage;
