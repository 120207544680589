import React from "react";

const OpensourceCard = ({ contributionData }) => {
  const { description, languages, name, primaryLanguage, updatedAt, url } = contributionData;
  const lastUpdatedAt = new Date(updatedAt).toDateString().split(" ")[2] + " " + new Date(updatedAt).toDateString().split(" ")[1] + " " + new Date(updatedAt).toDateString().split(" ")[3];
  return (
    <div className="col-xl-4 col-lg-6 col-md-6 d-flex">
      <div className="card shadow border-0 mb-0">
        <div className="card-body">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <div className="row">
              <div className="col-md-12">
                <img
                  src={`/languages/${primaryLanguage.name.toLowerCase()}.svg`}
                  width="50px"
                  height="45px"
                  className="img-responsive"
                  alt={`${name} contribution image`}
                />
              </div>
              <div className="col-md-12 opensource-desc py-4">
                <h6 className="mb-3">{primaryLanguage.name}</h6>
                <h5>{description ? description.split(" | ")[0] : name}</h5>
                <div className="osl-progressbar my-2">
                  {languages.nodes.map((languageInfo, index) => {
                    return <div key={index} style={{backgroundColor: `${languageInfo.color}`, width: `${calculateSingleLanguagePercent(languages.edges[index].size, languages.edges, "progress")}%`}}></div>
                  })}
                </div>
                <ul className="opensource-languages pl-0 list-unstyled">
                  {languages.nodes.map((languageInfo, index) => {
                    return (
                      <li key={index}>
                        <span className="osl-color-palette" style={{backgroundColor: `${languageInfo.color}`}}></span>
                        <span className="osl-name">{languageInfo.name}</span>
                        <span className="osl-size">{calculateSingleLanguagePercent(languages.edges[index].size, languages.edges, "text")}%</span>
                      </li>
                    )
                  })}
                </ul>
                <hr/>
                <p>Last updated on <strong>{lastUpdatedAt}</strong></p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

function calculateSingleLanguagePercent(singleEdge, groupEdges, edgeType){
  let singleLanguagePercent, finalGroupEdgesSize = 0;
  for(var i=0;i<groupEdges.length;i++){
    finalGroupEdgesSize = finalGroupEdgesSize + groupEdges[i].size
  }
  singleLanguagePercent = (singleEdge/finalGroupEdgesSize)*100;
  if(edgeType === "progress"){
    return singleLanguagePercent.toFixed(2);
  } else {
    return singleLanguagePercent.toFixed(1);
  }
}

export default OpensourceCard;
