import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";
import OpensourceRow from "./OpensourceRow";
import MetaData from "../../common/MetaData";
import {metaData} from "../../../config";
const Opensource = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiOpensourceHeaders {
        nodes {
          title
          description
        }
      }
      allStrapiOpensourceContributions {
        nodes {
          contributionsList {
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 70, width: 540, placeholder: NONE)
              }
            }
            link
            title
          }
        }
      }
    }
  `);
  const { title, description } = data.allStrapiOpensourceHeaders.nodes[0];
  return (
    <>
      <MetaData pageTitle="Opensource" description={metaData.opensource.description} keyword={metaData.opensource.keyword} />
      <section id="features" className="section opensource pt-5">
        <div className="container pt-10 pb-1">
          <div className="row gap-y align-items-center">
            <div className="col-lg-12 pt-5">
              <div className="section-heading text-center">
                <h2>{title}</h2>
                <ReactMarkdown
                  source={description}
                  className="muted div-text-center m-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section opensource design-two">
        <div className="container pt-1 pb-0">
          <div className="row gap-y align-items-center">
            <div className="col-lg-12">
              <OpensourceRow />
            </div>
          </div>          
        </div>
      </section>
    </>
  );
};

export default Opensource;
