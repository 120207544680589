import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import OpensourceCard from "./OpensourceCard";

const OpensourceRow = () => {
  const data = useStaticQuery(graphql`
    query {
      github {
        organization(login: "grootan") {
          repositories(
            privacy: PUBLIC
            first: 100
            isFork: false
            orderBy: {
              field: CREATED_AT,
              direction: DESC
            }
          ) {
            nodes {
              name
              url
              description
              primaryLanguage {
                name
              }
              languages(
                first: 100
              ) {
                nodes {
                  color
                  name
                }
                edges {
                  size
                }
              }
              updatedAt
            }
          }
        }
      }
    }
  `);
  return (
    <div className="row gap-y">
      {data.github.organization.repositories.nodes.map((contributionData, index) => (
        <OpensourceCard contributionData={contributionData} key={index} />
      ))}
    </div>
  );
};

export default OpensourceRow;
